import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getTransportations(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getTransportations`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },
}