function getDefaultTransportationObj() {
    return {
        plateNo: '',
        description: '',
        hasCheckingInterval: false,
        checkingInterval: 0,
        company: '',
        companyId: '',
        isActive: 'true',
        dateCreated: '',
        createdBy: '',
        dateUpdated: '',
        updatedBy: '',
    }
}

function cleanupFields(company) {
    let cleanedObj = { ...company };

    delete cleanedObj['status'];
    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
    delete cleanedObj['_showDetails'];

    return cleanedObj;
}


export const TransportationUtil = {
    getDefaultTransportationObj,
	cleanupFields
}