<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-truck"></i> Transportation</b-card-title>
			<b-card-sub-title>Manages all types of transport within the supply chain</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="companyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Plate No/CR/OR">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="plateNoOptions"
												:reduce="(company) => company.value" v-model="filterBy.plateNo">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-transportation v-show="!isViewer">
								Add Transportation
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Transportations in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Transportations to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="transportationTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

					<template v-slot:cell(plateNo)="row">
						<span class="numFont">
							{{ row.item.plateNo }}
						</span>
					</template>

					<template v-slot:cell(description)="row">
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
								type="html" :text="breakDescription(row.item.description, 25)" />
						</span>
					</template>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
							<b-button size="sm" v-show="isAllowedToEdit(row.item)" v-b-modal.edit-transportation v-b-tooltip.hover.top="'Edit Details'"
								variant="warning" @click.stop="updateSelTransportation(row.item)" class="mr-1">
								<i class="fa fa-pencil"></i>
							</b-button>
							<b-button size="sm" v-b-modal.print-transportation v-b-tooltip.hover.top="'Print QR Code'"
								variant="primary" @click.stop="printQrCode(row.item)" class="mr-1" v-show="!isViewer">
								<i class="fa fa-print"></i>
							</b-button>
						</span>
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelTransportation(row.item)" v-if="isAllowedToEdit(row.item)">
							<b-form-checkbox name="status" v-b-modal.activate-transportation v-model="row.item.isActive"
								switch size="sm">
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>
						{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<TransportationDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<AddTransportation :companyFilter="filterBy.company" :companyOptions="companyOptions"
			:allTransportationsObj="allTransportationsObj" />
		<EditTransportation :allCompaniesObj="allCompaniesObj" :companyOptions="companyOptions"
			:allTransportationsObj="allTransportationsObj" />
		<PrintTransportation />

		<ActivateTransportation />
	</div>
</template>

<script>
// Component
import AddTransportation from './transportation/AddTransportation';
import EditTransportation from './transportation/EditTransportation';
import ActivateTransportation from './transportation/ActivateTransportation';
import PrintTransportation from './transportation/PrintTransportation.vue';
import TransportationDetailsView from './transportation/TransportationDetailsView.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';

// API
import transportationApi from '@/api/transportationApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'transportation',
	components: {
		AddTransportation,
		EditTransportation,
		ActivateTransportation,
		TransportationDetailsView,
		PrintTransportation,
		Loading,
		JsonExcel,
		truncate
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'plateNo',
					label: 'Plate No/CR/OR',
					sortable: true,
				},
				{
					key: 'description',
					sortable: false,
				},
				{
					key: 'company',
					sortable: true,
				},
				'status',
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			companyOptions: [],
			statusOptions: config.statusOptionsWithDefault,
			plateNoOptions: [],

			allCompaniesObj: {},
			allTransportationsObj: {},
			selTransportation: {},

			defaultFilterBy: {
				company: { ...config.companyDefaultValue },
				isActive: 'true',
				plateNo: config.transportationDefaultValue,
			},
			filterBy: {
				company: { ...config.companyDefaultValue },
				isActive: 'true',
				plateNo: config.transportationDefaultValue,
			},
			prevFilter: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Plate No/CR/OR': 'plateNo',
				Description: 'description',
				Company: 'company',
				Status: 'status',
				'Has Checking Interval': 'hasCheckingInterval',
				'Checking Interval': 'checkingInterval',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
				'Created By': 'createdBy',
				'Updated By': 'updatedBy',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Transportation-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		if (this.isViewer) {
			this.fields = this.fields.filter((label) => {
				return label != 'actions';
			});
		}

		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.companyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

				this.allTransportationsObj = { ...this.$store.getters.transportations };
				this.plateNoOptions = DropDownItemsUtil.retrievePlateNos(this.allTransportationsObj);

				this.filterTableContent();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveTransportation', (transportationObj) => {
			this.updateTable(transportationObj);
		});
	},
	methods: {
		updateTable(transportationObj) {
			if (!_.isEmpty(transportationObj)) {
				this.allTransportationsObj[transportationObj.id] = transportationObj;
				this.$store.dispatch(
					'updateAllTransportations',
					this.allTransportationsObj
				);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObjs = { ...this.allTransportationsObj };

			_.forEach(filteredObjs, (transportation, transpoId) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== transportation.companyId
				) {
					delete filteredObjs[transpoId];
				}

				if (this.filterBy.isActive !== transportation.isActive) {
					delete filteredObjs[transpoId];
				}

				let plateNo = this.filterBy.plateNo;
				if (
					plateNo &&
					plateNo.length > 0 &&
					plateNo !== transportation.plateNo
				) {
					delete filteredObjs[transpoId];
				}
			});

			this.processTransportations(filteredObjs);			
		},
		processTransportations(transportationsObj){
			this.items = Object.values(transportationsObj);

			this.items.forEach((item) => {
				item['status'] = item.isActive === 'true' ? 'Active' : 'Inactive';
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);

				// remove show details
				delete item._showDetails;
			});
			this.items = _.sortBy(this.items, ['plateNo']);
			this.totalRows = this.items.length;

			this.$store.dispatch('updateAllTransportations', transportationsObj);

			// refresh table
			if (this.$refs.transportationsTable) {
				this.$refs.transportationsTable.refresh();
			}
		},

		async onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				this.allTransportationsObj = { ...this.$store.getters.transportations };
				this.filterTableContent();
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;

				if (this.isSuperAdmin) {
					const { data } = await transportationApi.getTransportations(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allTransportationsObj = _.assign(
						this.allTransportationsObj,
						data.transportations
					);
				} else {
					const { data } = await transportationApi.getTransportations(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allTransportationsObj = _.assign(
						this.allTransportationsObj,
						data.transportations
					);
				}

				this.filterTableContent();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		isAllowedToEdit(tranportation) {
			return this.isSuperAdmin || (!this.isViewer && UserUtil.hasCompanyAccess(this.allCompaniesObj, tranportation.companyId));
		},
		updateSelTransportation(item) {
			this.selTransportation = item;
			this.$store.commit('SET_CURR_TRANSPORTATION', item);
			EventBus.$emit('onUpdateSelTransportation', item);
		},
		printQrCode(item) {
			this.selTransportation = item;
			this.$store.commit('SET_CURR_TRANSPORTATION', item);
			EventBus.$emit('onPrintTransportation', item);
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		breakDescription(description, length) {
			return description.length > length ? description.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : description;
		}
	},
	beforeDestroy() {
		EventBus.$off('onCloseSaveTransportation');
	},
};
</script>
